import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Text from "../components/text/text"

const GroupssPage = () => (
	<Layout>
		<div className="heading-wrap">
			<div className="heading">
				<center>
					<h1>Групи і Студії</h1>
				</center>
			</div>
		</div>

		<Text>
			<p>
				Ми пропонуємо нашим маленьким відвідувачам простір, з оптимальним поєднанням завдань для фізичного, емоційного та інтелектуального розвитку у дружній доброзичливій атмосфері.<br />
				Ми віримо в дитину, в її ресурси та життєвий потенціал, і створюємо підтримуючі умови для їх реалізації.
			</p>
			<p>
				Основна програма включає:
			</p>
			<p>
				заняття з 8:00 до 19:00<br />
				4-разове харчування і 2 фруктові перекуси<br />
				основні та додаткові до основних занять студії: англійська, музика, фітнес<br />
				прикладну частину щомісяця:
			</p>
			<p>
				1 тиждень — художня репродукція<br />
				2 тиждень — музичний напрямок (класична, джаз, рок)<br />
				3 тиждень — тиждень пухнастиків. Браслети на ручку<br />
				4 тиждень — тиждень українознавства, культурознавства, календарних свят, валеології, ОБЖД<br />
				супровід психоаналітика і логопеда (за потреби)<br />
				Залежно від індивідуальних схильностей дитини, ми також можемо рекомендувати додаткові заняття або студії за окрему плату.
			</p>
			<p>
				Наші садочки мають державну ліцензію Міністерства Освіти і Науки України.<br />
				Вся наша команда офіційно працевлаштована.
			</p>
			<p>
				Вікові Групи<br />
				1,8 — 3 роки<br />
				Бебіки
			</p>
			<p>
				Всі навколо помічають, що приблизно в два роки дитина починає активно і самостійно діяти. Ідентичність дитини на цій стадії може бути позначена формулою, яка знайома батькам: «Я сам» або «Я – те, що я можу». Важливо зазначити, що розумна дозволеність сприяє становленню автономії дитини. Тому в ході взаємодії з дітьми, ми активно і м’яко розвиваємо навички самообслуговування у наших малят. Навчальні тижні дітлахів насичені та цікаві, багато дізнаємось про навколишній світ, формуємо уявлення про властивості предметів, збагачуємо знання про об’єкти живої природи та їх особливості. Також приділяємо увагу розвитку мовлення, рухової активності, творчих вмінь та комунікативних навичок.
			</p>
			<p>
				3 – 4 роки<br />
				Чомусики
			</p>
			<p>
				Вік, який називають віком «чомусиків»
			</p>
			<p>
				Діти цієї вікової категорії хочуть знати все на світі, часто називають себе вже «великими» і «дорослими». В цей період розвитку дитини, ми активно приділяємо увагу сюжетно-рольовим іграм, які розвивають соціальні та комунікативні навички, вчать емпатії – уявляючи себе в різних ролях дитина потроху вчиться розуміти людей в різних обставинах.
			</p>
			<p>
				Методична програма включає в себе елементи сенсомоторного розвитку, основи геометрії, ігрової математики, розвитку мовлення. Діти у віці 3-4 роки надзвичайно спритні та активні, вони вже так багато можуть! На заняттях з фітнесу та під час прогулянок, ми підтримуємо їх природнє прагнення до руху та розвитку власного тіла.
			</p>
			<p>
				Такий широкий спектр емоцій та почуттів тепер доступний нашим «чомусикам» – до основних з них додаються нудьга, страх, відчуття щастя та інші. Ми продовжуємо вчитись висловлювати свої емоції, приймати їх та шукати варіанти конструктивної взаємодії.
			</p>
			<p>
				4 -5 років<br />
				Розумчики
			</p>
			<p>
				Вікова група від 4 до 5 років – це діти, які активно прагнуть бути самостійними. Вони набагато менше потребують опіки дорослих і вже більше здатні подбати про себе, вони отримують колосальне задоволення від таких практичних дій в садочку, як то: зустріч одногрупників в їдальні, догляд за квітами, допомога вихователю в підготовці до заняття.
			</p>
			<p>
				У дитини в цей час розширюються свідомі емоції, вона стає здатною співпереживати, співчувати, більше розуміти почуття інших людей.
			</p>
			<p>
				В методичній програмі ми враховуємо природній розвиток свідомості в дітей. Тепер в своєму пізнанні вони здатні виходити за межі того, з чим безпосередньо стикаються — вони можуть поступово накопичувати фактичні знання про різні предмети та явища, про які знають лише зі слів дорослого, починають знайомитись з цифрами та літерами.
			</p>
			<p>
				5 – 6 років<br />
				Preschool
			</p>
			<p>
				Вік 5-6 років це старший дошкільний вік. Він є дуже важливим у розвитку пізнавальної, інтелектуальної та особистісної сфер дитини.
			</p>
			<p>
				У 5-6 років дитина як губка вбирає всю пізнавальну інформацію. Науково доведено, що дитина в цьому віці запам’ятовує стільки матеріалу, скільки вона не запам’ятає потім ніколи в житті. В цей період дітям цікаво все, що пов’язано з навколишнім світом, розширенням їх кругозору і тематика наших методичних «Сезонів» підтримує це прагнення.
			</p>
			<p>
				Цей період називають сензитивним (сприятливим) для розвитку всіх пізнавальних процесів: уваги, сприйняття, мислення, пам’яті, уяви.
			</p>
			<p>
				Також в цей період розпочинаються заняття з підготовки до школи.
			</p>
			<p>
				Ранній розвиток окремий і важливий напрямок у діяльності сім’ї Lady&Baby. Ми є лідерами у нашому мікрорайоні і за 6 років діяльності ми випустили без перебільшення близько тисячі маленьких студентів
			</p>
			<p>
				Заняття з раннього розвитку направлені на всебічний розвиток дитини:
			</p>

			<ul>
				<li>емоційний;</li>
				<li>соціальний;</li>
				<li>інтелектуальний;</li>
				<li>фізичний;</li>
				<li>творчий;</li>
				<li>сенсомоторний;</li>
				<li>музичний</li>
				<li>та розвиток мовлення.</li>
			</ul>

			<p>
				Заняття поєднують у собі різноманітні методики раннього розвитку:
			</p>

			<ul>
				<li>Елементи Марії Монтессорі;</li>
				<li>Картки Глена Домана;</li>
				<li>Кубики Зайцева;</li>
				<li>Музичне виховання за методикою К.Орфа;</li>
				<li>та ін</li>
			</ul>

			<p>
				Для нашого центру дуже важливим є соціальна адаптація малечі у групі.
			</p>
			<p>
				На заняттях дітки вчаться спілкуватися один з одним та звикають до нових облич, як дорослих, так і дітей.
			</p>
			<p>
				Заняття тематичні: за допомогою гри та творчості дітки занурюються в певну тематику. Наприклад, ми знайомимо діток з явищами природи, з рослинністю, тваринами, птахами та комахами характерними для певного сезону.
			</p>
			<p>
				Діяльність діток на занятті побудована з урахуванням їхніх вікових особливостей.
			</p>
			<p>
				Статичні завдання змінюються активними іграми, танцями, пальчиковою грою, забавлянками, артикуляційною гімнастикою та іншими видами діяльності.
			</p>
			<p>
				Заняття має певну структуру, завжди починається з ритуалу вітання та закінчується ритуалом прощання.
			</p>
			<p>
				Так як ми підтримуємо та заохочуємо прояви творчості у малюку, кожне заняття завершується творчим блоком, на якому навіть найменші дітки малюють у різних техніках, ліплять з солоного тіста, рвуть папір, створюють власні аплікації та конструюють.
			</p>

			<p>
				Студії
			</p>

			<ul>
				<li>Хореографічна студія</li>
				<li>Студія акторської майтерності</li>
				<li>Футбольна секція</li>
				<li>Індивідуальні і групові заняття з логопедом</li>
				<li>Англійська мова</li>
				<li>Дитячий фітнес</li>
				<li>Музичні заняття за системою Карла Орфа</li>
			</ul>
		</Text>
	</Layout>
)

export const Head = () => <Seo title="Групи і Студії" />

export default GroupssPage
